<template>
  <div class="VSelect" @click="toggleItems">
    {{ label }}
    <div
      class="VSelect__filed VSelect__placeholder"
      :class="{ disabled, focused: open, invalid }"
      v-if="value.id === null || value.id < 0"
    >
      {{ placeholder }}
    </div>
    <div
      class="VSelect__filed"
      :class="{ disabled, focused: open, invalid }"
      v-else
    >
      {{ value.name }}
    </div>
    <div class="VSelect__items--wrapper" v-if="open">
      <div class="VSelect__items">
        <div
          class="VSelect__error"
          v-if="errorLoadItemsMessage"
          @click="$emit('reload')"
        >
          {{ errorLoadItemsMessage }}
          <svg
            class="VSelect__errorIcon"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0472 2.06099C14.562 1.86109 15.1415 2.11639 15.3414 2.63123L16.6082 5.89391C16.8081 6.40874 16.5528 6.98816 16.038 7.18806L12.7753 8.45492C12.2605 8.65482 11.6811 8.39952 11.4812 7.88468C11.2812 7.36985 11.5366 6.79044 12.0514 6.59053L13.272 6.11657C12.319 5.94046 11.3352 5.96338 10.3836 6.18921C9.03658 6.5089 7.81458 7.22085 6.87215 8.23505C5.92973 9.24924 5.30921 10.5201 5.08907 11.887C4.86892 13.2538 5.05904 14.6553 5.63539 15.9141C6.21173 17.1729 7.14841 18.2325 8.32697 18.959C9.50554 19.6854 10.8731 20.0461 12.2566 19.9953C13.6401 19.9446 14.9776 19.4847 16.0997 18.6739C17.2219 17.863 18.0784 16.7376 18.561 15.44L18.5632 15.4408C18.8494 14.6727 19.0198 13.8516 19.0546 13C19.0772 12.4482 19.5453 12.0187 20.1002 12.0407C20.655 12.0627 21.0865 12.528 21.0639 13.0798C21.0119 14.3509 20.7407 15.4984 20.2621 16.5691C19.6201 18.0552 18.5878 19.3435 17.2711 20.295C15.8283 21.3375 14.1087 21.9287 12.3299 21.994C10.5511 22.0592 8.79283 21.5955 7.27753 20.6615C5.76224 19.7275 4.55794 18.3651 3.81692 16.7467C3.07591 15.1282 2.83147 13.3263 3.11451 11.569C3.39755 9.81158 4.19536 8.17759 5.40705 6.87362C6.61874 5.56966 8.18989 4.65429 9.92182 4.24326C11.1994 3.94007 12.5221 3.92135 13.7978 4.18142L13.477 3.35515C13.2771 2.84031 13.5324 2.2609 14.0472 2.06099Z"
              fill="#262626"
            />
          </svg>
        </div>
        <div
          class="VSelect__item"
          v-for="item in items"
          :key="item.id"
          @click="onSelectItem(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    invalid: {
      type: Boolean,
      required: false,
    },
    errorLoadItemsMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  created() {
    window.addEventListener('click', this.onExternalClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onExternalClick);
  },
  methods: {
    toggleItems(event) {
      event.stopPropagation();
      if (
        this.items.length > 0 ||
        !this.disabled ||
        this.errorLoadItemsMessage
      ) {
        this.open = !this.open;
      }
    },
    onExternalClick() {
      this.open = false;
    },
    onSelectItem(item) {
      this.$emit('input', item);
    },
  },
};
</script>

<style lang="less">
.VSelect {
  position: relative;
  font-size: 2vh;
  transition: 100ms;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.VSelect__filed {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  cursor: none;
  border-radius: 3.5vh;
  margin-top: 8px;
  padding: 2.2vh 5vw;
  border: 1px solid #e7e9eb;
  font-size: 2vh;
  font-weight: 400;
  font-family: @Euclid;
  background: #fff;
  user-select: none;
  transition: 100ms;
  @media screen and (min-width: 768px) {
    padding: 1.5vh 1.7vw;
    font-size: 1.2vh;
    border-radius: 2.5vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.VSelect__filed::after {
  content: '';
  position: absolute;
  background-image: url(../../assets/images/keyboard_arrow_down-24px.svg);
  background-size: 2.5vh 2.5vh;
  background-position: center center;
  background-repeat: no-repeat;
  width: 2vh;
  height: 2vh;
  right: 1.2vh;
  top: calc(50% - 1vh);
  transition: 100ms;
}
.VSelect__filed.focused::after {
  transform: rotate(180deg);
}
.VSelect__placeholder {
  color: #b1b4b7;
  font-size: 2vh;
  font-weight: 300;
  font-family: @EuclidLight;
  user-select: none;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.disabled {
  background: #e5e6e8;
  color: #b1b4b7;
}
.VSelect__filed.focused,
.VSelect__placeholder.focused {
  border: 1px solid #000;
}
.VSelect__placeholder.invalid,
.VSelect__items.invalid {
  border: 1px solid #d8394c;
  color: #d8394c;
}
.VSelect__items--wrapper {
  position: absolute;
  width: 100%;
  margin-top: 8px;
  padding: 1vh;
  box-shadow: 0px 4px 14px rgba(38, 38, 38, 0.18);
  border-radius: 3.5vh;
  background: #fff;
  z-index: 9999;
  @media screen and (min-width: 768px) {
    border-radius: 2vh;
  }
}
.VSelect__items {
  width: 100%;
  max-height: 20vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0.5vh 3vw;
  @media screen and (min-width: 768px) {
    padding: 0.2vh 0.5vw;
  }
}
.VSelect__item {
  font-size: 2vh;
  font-weight: 300;
  font-family: @EuclidLight;
  padding: 1vh 0;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.VSelect__item + .VSelect__item {
  border-top: 1px solid #e7e9eb;
}
.VSelect__error {
  font-size: 2vh;
  color: #d8394c;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
}
.VSelect__errorIcon {
  display: block;
  width: 26px;
  height: 26px;
  margin: 1vh auto 0.4vh;
}
</style>
