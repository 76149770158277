<template>
  <div class="VTextarea">
    <label class="VTextarea__label">
      {{ label }}
      <textarea
        class="VTextarea__input"
        :class="{ focused, invalid }"
        :value="value"
        :placeholder="placeholder"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      >
      </textarea>
    </label>
  </div>
</template>

<script>
export default {
  name: 'VTextarea',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    invalid: {
      type: Boolean,
      required: false,
    },
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>

<style lang="less">
.VTextarea {
  transition: 100ms;
  cursor: none;
}
.VTextarea__label {
  display: block;
  font-size: 2vh;
  cursor: none;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.VTextarea__input {
  width: 100%;
  cursor: none;
  border-radius: 3.5vh;
  margin-top: 8px;
  padding: 2.2vh 5vw;
  border: 1px solid #e7e9eb;
  font-size: 2vh;
  font-weight: 400;
  font-family: @Euclid;
  background: #fff;
  transition: 100ms;
  resize: none;
  @media screen and (min-width: 768px) {
    padding: 1.5vh 1.7vw;
    font-size: 1.2vh;
    border-radius: 2.5vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.VTextarea__input::placeholder {
  color: #b1b4b7;
  font-size: 2vh;
  font-weight: 300;
  font-family: @EuclidLight;
  @media screen and (min-width: 1024px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.VTextarea__input.focused {
  border: 1px solid #000;
}
.VTextarea__input.invalid {
  border: 1px solid #d8394c;
  color: #d8394c;
}
.VTextarea__input.invalid::placeholder {
  color: #d8394c;
}
</style>
