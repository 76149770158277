



















import { VueMaskDirective } from 'v-mask';
export default {
  name: 'VInput',
  directives: { mask: VueMaskDirective },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    mask: {
      type: String,
      required: false,
      default: () => null,
    },
    invalid: {
      type: Boolean,
      required: false,
    },
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
