



















import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class Toggle extends Vue {
  @Prop()
  name!: string;

  @Prop()
  value!: any;

  @Model('input', { type: Boolean }) checked!: boolean;
  @Prop({ type: Boolean }) public disabled!: boolean;

  get model() {
    return this.checked;
  }

  set model(val) {
    this.$emit('input', val);
  }
}
