<template>
  <button class="VButton">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {},
};
</script>

<style lang="less">
.VButton {
  background: @green;
  padding: 1.7vh 2vw;
  font-family: @Euclid;
  font-size: 2vh;
  border-radius: 5vh;
  border: none;
  text-transform: lowercase;
  cursor: none;
  transition: 200ms;
}
.VButton:hover {
  background: @green2;
}
</style>
