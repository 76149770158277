<template>
  <div class="fos-form-wrapper">
    <div class="half half-reverse content fos-content">
      <div
        class="half__text FOSStep__mobile"
        v-if="currentFOSStep === 'mobile'"
      >
        <div>
          {{ $t('lang.fos.easy-block') }}
        </div>
        <a
          href="delimobil://fos"
          target="_blank"
          class="FOSStep__mobile--button"
        >
          {{ $t('lang.fos.open-app') }}
        </a>
        <span @click="stayOnWeb = true">
          {{ $t('lang.fos.open-site') }}
        </span>
      </div>
      <div
        class="half__text fos-form--full-height"
        v-if="currentFOSStep === 'form'"
      >
        <h2 class="h2">{{ $t('lang.fos.title') }}</h2>
        <p class="sub-title fos-form__sub-title">
          {{ $t('lang.fos.description') }}
        </p>
        <div class="fos-contacts" v-if="false">
          <v-button class="fos-contacts__contact">
            <a href="https://vk.com/im?sel=-97106498" target="_blank">
              {{ $t('lang.fos.contacts.vk') }}
            </a>
          </v-button>
          <v-button class="fos-contacts__contact">
            <a href="https://www.instagram.com/delimobil/" target="_blank">
              {{ $t('lang.fos.contacts.instagram') }}
            </a>
          </v-button>
          <v-button class="fos-contacts__contact">
            <a href="https://m.me/delimobil.carsharing" target="_blank">
              {{ $t('lang.fos.contacts.fb') }}
            </a>
          </v-button>
        </div>
        <button
          class="VButton button-mail"
          v-if="!mailChoose"
          v-on:click="mailChoose = true"
        >
          {{ $t('lang.fos.contacts.mail') }}
        </button>
        <ValidationObserver
          v-if="mailChoose"
          tag="div"
          v-slot="{ handleSubmit }"
        >
          <form
            class="fos-form"
            @submit.prevent="handleSubmit(handlerShowCaptcha)"
          >
            <ValidationProvider
              name="firstName"
              rules="required"
              v-slot="{ errors }"
              class="fos-form-col"
            >
              <VInput
                v-model="form.firstName"
                :label="$t('lang.fos.name')"
                :placeholder="$t('lang.fos.name-placeholder')"
                :invalid="errors.length > 0"
              />
            </ValidationProvider>
            <ValidationProvider
              name="lastName"
              rules="required"
              v-slot="{ errors }"
              class="fos-form-col"
            >
              <VInput
                v-model="form.lastName"
                :label="$t('lang.fos.surname')"
                :placeholder="$t('lang.fos.surname-placeholder')"
                :invalid="errors.length > 0"
              />
            </ValidationProvider>
            <ValidationProvider
              name="Phone"
              rules="required"
              v-slot="{ errors }"
              class="fos-form-col"
            >
              <VInput
                v-model="form.phone"
                :label="$t('lang.fos.phone')"
                placeholder="+7 ——— ——— —— ——"
                mask="+7 ### ### ## ##"
                :invalid="errors.length > 0"
              />
            </ValidationProvider>
            <ValidationProvider
              name="E-mail"
              rules="required|email"
              v-slot="{ errors }"
              class="fos-form-col"
            >
              <VInput
                v-model="form.email"
                :label="$t('lang.fos.email')"
                :placeholder="$t('lang.fos.email-placeholder')"
                :invalid="errors.length > 0"
              />
            </ValidationProvider>
            <ValidationProvider
              name="category"
              rules="positive_id"
              v-slot="{ errors }"
              class="fos-form-col"
            >
              <VSelect
                v-model="category"
                :label="$t('lang.fos.category')"
                :placeholder="$t('lang.fos.category-placeholder')"
                :items="categories"
                :invalid="errors.length > 0"
                :errorLoadItemsMessage="errorLoadItemsMessage"
                @reload="getCategories"
              />
            </ValidationProvider>
            <ValidationProvider
              name="subcategory"
              rules="positive_id"
              v-slot="{ errors }"
              class="fos-form-col"
            >
              <VSelect
                v-model="subcategory"
                :label="$t('lang.fos.subcategory')"
                :placeholder="$t('lang.fos.subcategory-placeholder')"
                :items="subcategories"
                :disabled="form.categoryId < 0 || form.categoryId === null"
                :invalid="errors.length > 0"
              />
            </ValidationProvider>
            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
              class="fos-form-full"
            >
              <VTextarea
                v-model="form.message"
                :label="$t('lang.fos.message')"
                :invalid="errors.length > 0"
                class="fos-form-message"
              />
            </ValidationProvider>
            <label class="fos-form-full file" :key="attachments.length">
              <div class="fos-form-secondary">
                <b class="fos-form-primary"> {{ $t('lang.fos.file-1') }} </b
                ><br />
                {{ $t('lang.fos.file-2') }}
              </div>

              <input
                type="file"
                multiple
                name="attachment"
                @change="attachmentChange($event.target.files)"
                accept="image/*"
                class="input-file"
              />
            </label>

            <div class="fos-form-full fos-form-files">
              <div
                class="fos-form-file"
                v-for="(file, index) in attachments"
                :key="`${index}${file.name}`"
              >
                {{ file.name }}
                <div class="fos-form-file__remove" @click="removeFile(file)">
                  +
                </div>
              </div>
            </div>
            <div class="fos-form-full hr"></div>
            <div class="fos-form-col fos-form-secondary fos-form-agreement">
              <VSwitch v-model="isAgreementAccepted" />
              <p class="fos-form-agreement-title">
                <span>
                  {{ $t('lang.fos.data-processing') }}
                </span>
                <router-link
                  :to="{ name: 'FOSAgreement' }"
                  class="fos-form-secondary"
                >
                  {{ $t('lang.fos.data-processing-link') }}
                </router-link>
              </p>
            </div>
            <div class="fos-submit">
              <VButton type="submit" :disabled="!isAgreementAccepted">
                {{ $t('lang.fos.submit') }}
              </VButton>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="half__text" v-if="currentFOSStep === 'complete'">
        <template v-if="formSuccess">
          <div class="h2">{{ $t('lang.fos.thx-title') }}</div>
          <div>
            {{ $t('lang.fos.thx-desc', { email: form.email }) }}
          </div>
          <VButton class="FOSStep__mobile--button" @click.native="restartForm">
            {{ $t('lang.fos.more') }}
          </VButton>
        </template>
        <template v-else>
          <div class="h2">{{ $t('lang.fos.error-title') }}</div>
          <div>
            {{ $t('lang.fos.error-desc') }}
          </div>
          <VButton class="FOSStep__mobile--button" @click.native="retryForm">
            {{ $t('lang.fos.error-more') }}
          </VButton>
        </template>
      </div>
    </div>
    <div class="fos-popup" v-if="isShowCaptcha">
      <div class="fos-popup-content">
        <span class="fos-popup-close" @click="hideCaptcha">+</span>
        <h3 class="h3">{{ $t('lang.fos.captcha.title') }}</h3>
        <p class="sub-title">{{ $t('lang.fos.captcha.description') }}</p>
        <img :src="computedCaptcha" alt="Captcha" class="captcha-image" />
        <p class="sub-title" v-if="countCaptcha < 3" @click="getNewCaptcha">
          {{ $t('lang.fos.captcha.another') }}
        </p>
        <p class="sub-title" v-else>
          {{ $t('lang.fos.captcha.another2', { count: countCaptchaDelay }) }}
        </p>
        <div class="flex">
          <VInput
            v-model="captchaValue"
            label=""
            placeholder=""
            @keyup.enter.native="handlerSubmitForm"
          />
          <div
            class="fos-popup-submit"
            :class="{ active: filledCaptcha }"
            @click="handlerSubmitForm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ENV from '@/environment';
import axios from 'axios';
import { isMobile } from '@/mixins/isMobile';
import { functions } from '@/mixins/functions';

import VInput from '@/components/ui/VInput.vue';
import VSelect from '@/components/ui/VSelect.vue';
import VButton from '@/components/ui/VButton.vue';
import VTextarea from '@/components/ui/VTextarea.vue';
import VSwitch from '@/components/ui/VSwitch.vue';

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
extend('positive_id', {
  validate(value) {
    return value.id > 0;
  },
});

export default {
  name: 'FOSForm',
  mixins: [isMobile, functions],
  components: {
    ValidationObserver,
    ValidationProvider,
    VInput,
    VSelect,
    VButton,
    VTextarea,
    VSwitch,
  },
  data() {
    return {
      pageData: { page: 'FOS', isHeaderHidden: true },
      form: {
        categoryId: null,
        subcategoryId: null,
        topic: '',
        message: '',
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
      },
      mailChoose: true,
      isAgreementAccepted: false,
      apiUrl: ENV.APP_API_HOST,
      catagoriesData: [],
      formComplete: false,
      formSuccess: false,
      stayOnWeb: false,
      errorLoadCategories: false,
      attachments: [],
      isShowCaptcha: false,
      captchaValue: '',
      captcha: {
        id: null,
        value: '',
      },
      countCaptcha: 1,
      countCaptchaDelay: 0,
      captchaInterval: null,
      isSendingForm: false,
    };
  },
  computed: {
    currentFOSStep() {
      if (this.formComplete) {
        return 'complete';
      }
      if (this.isMobile && !this.stayOnWeb) {
        return 'mobile';
      }
      return 'form';
    },
    categories() {
      return this.catagoriesData.map((category) => {
        return {
          id: category.id,
          name: category.title,
        };
      });
    },
    subcategories() {
      if (this.catagoriesData.length === 0) {
        return [];
      }
      if (!this.form.categoryId) {
        return [];
      }
      const [{ subcategories }] = this.catagoriesData.filter(
        (category) => category.id === this.form.categoryId,
      );
      return subcategories.map((subcategory) => {
        return {
          id: subcategory.id,
          name: subcategory.title,
        };
      });
    },
    category: {
      get() {
        const index = this.categories.findIndex(
          (category) => category.id === this.form.categoryId,
        );
        if (index >= 0) {
          return this.categories[index];
        }
        return {
          id: null,
          name: '',
        };
      },
      set(value) {
        this.form.categoryId = value.id;
      },
    },
    subcategory: {
      get() {
        const index = this.subcategories.findIndex(
          (category) => category.id === this.form.subcategoryId,
        );
        if (index >= 0) {
          return this.subcategories[index];
        }
        return {
          id: null,
          name: '',
        };
      },
      set(value) {
        this.form.subcategoryId = value.id;
      },
    },
    errorLoadItemsMessage() {
      if (this.errorLoadCategories) {
        return this.$t('lang.fos.errorLoadCategoriesMessage');
      }
      return '';
    },
    computedCaptcha() {
      if (this.captcha.imageEncoded) {
        return `data:image/png;base64,${this.captcha.imageEncoded}`;
      }
      return '';
    },
    filledCaptcha() {
      return this.captchaValue.length >= 5;
    },
  },
  created() {
    this.getCategories();
  },
  deactivated() {
    this.isLoaded = false;
  },
  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },
  watch: {
    form: {
      handler(value) {
        if (value) {
          localStorage.setItem('feedbackForm', JSON.stringify(value));
        }
      },
      deep: true,
    },
    '$i18n.locale': {
      handler() {
        this.getCategories();
      },
    },
  },
  methods: {
    async onActivated() {
      const form = localStorage.getItem('feedbackForm');
      if (form) {
        const data = JSON.parse(form);
        this.form.categoryId = data.categoryId;
        this.form.subcategoryId = data.subcategoryId;
        this.form.topic = data.topic;
        this.form.message = data.message;
        this.form.email = data.email;
        this.form.phone = data.phone;
        this.form.firstName = data.firstName;
        this.form.lastName = data.lastName;
      }
    },
    async handlerSubmitForm() {
      if (!this.filledCaptcha || this.isSendingForm) {
        return;
      }
      this.isSendingForm = true;
      const formData = new FormData();
      let topic = this.subcategory.name;

      formData.append('categoryId', this.form.categoryId);
      formData.append('subcategoryId', this.form.subcategoryId);
      if ('storecomments' in this.$route.query) {
        topic = `[Store comments]{${this.subcategory.name}}`;
      }
      formData.append('topic', topic);
      formData.append('message', this.form.message);
      formData.append('email', this.form.email);
      formData.append('phone', this.form.phone);
      formData.append('firstName', this.form.firstName);
      formData.append('lastName', this.form.lastName);

      formData.append('captchaId', this.captcha.id);
      formData.append('captchaValue', this.captchaValue);
      formData.append('utm_source', this.$route.query.utm_source || '');

      for (const file of this.attachments) {
        formData.append('attachmentLinks', file);
      }

      axios({
        method: 'post',
        url: `${this.apiUrl}/api/feedback/send-form`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          this.formSuccess = true;
          localStorage.removeItem('feedbackForm');
        })
        .catch(() => {})
        .finally(() => {
          this.formComplete = true;
          this.isShowCaptcha = false;
          this.isSendingForm = false;
        });
    },
    restartForm() {
      this.stayOnWeb = true;

      this.formComplete = false;
      this.formSuccess = false;

      this.form = {
        categoryId: null,
        subcategoryId: null,
        topic: '',
        message: '',
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
      };
      this.attachments = [];
    },
    retryForm() {
      this.stayOnWeb = true;

      this.formComplete = false;
      this.formSuccess = false;
    },
    getCaptcha() {
      return axios
        .get(`${this.apiUrl}/api/feedback/captcha`)
        .then((data) => {
          this.captchaValue = '';
          this.captcha = { ...data.data };
        })
        .catch(() => {
          // this.errorLoadCategories = true;
        });
    },
    async getNewCaptcha() {
      if (this.countCaptcha < 3) {
        this.countCaptchaDelay = 20;
        await this.getCaptcha();
        this.countCaptcha += 1;
        if (this.countCaptcha >= 3) {
          this.startCaptchaTimer();
        }
        return;
      }
      if (this.countCaptchaDelay > 0) {
        this.countCaptchaDelay -= 1;
      }
      if (this.countCaptchaDelay <= 0) {
        clearInterval(this.captchaInterval);
        this.countCaptcha = 0;
      }
    },
    startCaptchaTimer() {
      this.captchaInterval = setInterval(this.getNewCaptcha, 1000);
    },
    async handlerShowCaptcha() {
      await this.getCaptcha();
      this.isShowCaptcha = true;
    },
    hideCaptcha() {
      this.isShowCaptcha = false;
    },
    getCategories() {
      axios({
        method: 'get',
        url: `${this.apiUrl}/api/feedback/categories?lang=${this.$root.$i18n.locale}`,
      })
        .then((data) => {
          this.catagoriesData = [...data.data.categories];
        })
        .catch(() => {
          this.errorLoadCategories = true;
        });
    },
    attachmentChange(files) {
      if (!files?.length) return;

      for (const file of files) {
        this.attachments.push(file);
      }
    },
    removeFile(file) {
      const filteredFiles = this.attachments.filter(
        (att) => att.name !== file.name || att.size !== file.size,
      );

      this.attachments = [];

      for (const file of filteredFiles) {
        this.attachments.push(file);
      }
    },
  },
};
</script>

<style lang="less">
.fos-page {
  .half__pic {
    height: 46vh;
    @media screen and (min-width: 768px) {
      height: 100vh;
    }
  }
}
.fos-content {
  font-size: 2vh;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4vh;
  }
  .h2 {
    font-size: 5vh;
    @media screen and (min-width: 1024px) {
      font-size: 5vh;
      margin-bottom: 1vh;
    }
  }
}
.fos-form-wrapper {
  height: 100%;
  padding-bottom: 4.58333333vw;
}
.fos-form--full-height {
  height: 100%;
}
.fos-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 18px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 26px;
  }
}
.fos-form-col {
  box-sizing: border-box;
  align-self: stretch;
  justify-self: stretch;
}
.fos-form-full {
  box-sizing: border-box;
  grid-column: 1;
  @media screen and (min-width: 1024px) {
    grid-column: 1 / span 2;
  }
}
.fos-submit {
  text-align: center;
  button {
    width: 100%;
    padding: 3vh 0;
    font-size: 3vh;
  }
  @media screen and (min-width: 768px) {
    button {
      margin: 1vh 0;
      padding: 1.4vh 2vw;
      font-size: 2vh;
    }
  }
  @media screen and (min-width: 1024px) {
    text-align: right;
    button {
      width: auto;
      padding: 1.7vh 2vw;
      font-size: 2vh;
    }
  }
}
.fos-form-primary {
  color: #262626;
}
.fos-form-secondary {
  color: #6d7275;
}
.file {
  padding: 2vh 2vh 2vh 6vh;
  position: relative;
  width: 100%;
  border: 2px dashed #000;
  border-radius: 3.5vh;
  cursor: none;
  @media screen and (min-width: 768px) {
    padding: 1.5vh 1.5vh 1.5vh 6vh;
    border-radius: 2.5vh;
  }
  .input-file {
    opacity: 0;
    position: absolute;
  }
}
.fos-form-files {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.fos-form-file {
  position: relative;
  display: inline-block;
  padding: 1vh 5vh 1vh 2vh;
  background: #f6f7fb;
  border-radius: 3vh;
  color: #262626;
  margin: 0.8vh;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 230px;
  @media screen and (min-width: 1024px) {
    margin: 0.5vh;
    padding: 0.8vh 4vh 0.8vh 1.5vh;
    max-width: 100%;
  }
}
// .fos-form-file + .fos-form-file {
//   margin-left: 0;
//   @media screen and (min-width: 1024px) {
//     margin-left: 1vh;
//   }
// }
.fos-form-file__remove {
  position: absolute;
  right: 0.6vh;
  width: 4vh;
  height: 4vh;
  top: calc(50% - 2vh);
  font-size: 3.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: rotate(0.125turn);
  @media screen and (min-width: 1024px) {
    top: calc(50% - 1vh);
    right: 1vh;
    width: 2vh;
    height: 2vh;
    font-size: 2.5vh;
  }
}
.fos-form-agreement {
  display: flex;

  &-title {
    margin-left: 15px;
  }
}
.hr {
  height: 1vh;
  border-bottom: 2px solid #b1b4b7;
}
.FOSStep__mobile {
  text-align: center;
}
.FOSStep__mobile--button {
  display: block;
  text-decoration: none;
  background: @green;
  padding: 1.7vh 2vw;
  font-family: @Euclid;
  font-size: 2vh;
  border-radius: 5vh;
  border: none;
  text-transform: lowercase;
  cursor: none;
  transition: 200ms;

  width: 100%;
  margin: 5vh 0;
  padding: 3vh 0;
  font-size: 3vh;
  @media screen and (min-width: 1024px) {
    width: auto;
    margin: 3vh 0;
    padding: 1.7vh 2vw;
    font-size: 2vh;
  }
}
.fos-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.8);
}
.fos-contacts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid black;
  padding-bottom: 2vh;
  margin-bottom: 2vh;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.fos-contacts__contact:not(:last-child),
.fos-form__sub-title {
  margin-bottom: 1vh;
}
.fos-contacts button a {
  text-decoration: none;
}
.fos-popup-close {
  position: absolute;
  top: 2vh;
  right: 2vh;
  font-size: 3vh;
  transform: rotate(0.125turn);
  background: #e6e6ea;
  border-radius: 100%;
  width: 3vh;
  height: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0.4vh 0;
}
.fos-popup-content {
  position: relative;
  box-sizing: border-box;
  min-width: 100%;
  min-height: auto;
  max-width: 60vw;
  max-height: 80vh;
  padding: 4vh;
  background: #fff;
  border-radius: 2vh;
  font-size: 2vh;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
    min-width: 526px;
    min-height: 375px;
    max-width: 60vw;
    max-height: 40vh;
    padding: 3vh;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4vh;
  }
  .h3 {
    font-size: 4vh;
    text-transform: none;
    @media screen and (min-width: 1024px) {
      font-size: 2.5vh;
      margin-bottom: 1vh;
    }
  }
  .sub-title {
    margin-bottom: 3vh;
  }
  .VInput {
    width: 200px;
  }
  .VInput__input {
    margin: 0;
    padding: 2vh 4vh;
    border: 1px solid #28e1b9;
    @media screen and (min-width: 1024px) {
      padding: 1vh 2vh;
    }
  }
}
.captcha-image {
  margin-bottom: 1vh;
  border-radius: 8px;
  width: 200px;
  height: auto;
}
.button-mail {
  width: 100%;
  text-transform: uppercase;
  background-color: #fff;
}

.fos-popup-submit {
  width: 6vh;
  height: 6vh;
  background: #f0f0f0;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
  margin-left: 1vh;

  @media screen and (min-width: 1024px) {
    width: 4vh;
    height: 4vh;
  }
  &.active {
    background: #28e1b9;
    svg {
      fill: #262626;
    }
  }
  svg {
    fill: #a7a2a9;
    width: 60%;
    height: 60%;
  }
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
